input::-ms-reveal,
input::-ms-clear {
  display: none;
}

html,
body,
#root {
  height: 100%;
  --topbar-height: 60px;
  --mobile-topbar-height: 50px;
  box-sizing: border-box;
}

body {
  background-color: #f4f4f4;
}
